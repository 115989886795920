const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const variables = {
  familyBase: '"proxima-nova",sans-serif',
  familyHeader: '"proxima-nova",serif',
  primaryColor: '#0873ba',
  fontColor: '#555555',
  bgColor: '#ebebeb',
  blueColor : '#0873ba',
  lightBlueColor : '#00b8f1',
  ...overloading
}
export default variables;